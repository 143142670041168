import React from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const Tutorial = () => {
  return (
    <div className="tutorial">
      <div className="tutorial-text">
        <h1>Installing App</h1>
        <p>
          LearnLyric is a Progressive Web Application (PWA). It means that while
          it runs inside your browser, you can install it like a native app.
          It works offline and will be updated automatically each time you re-open it.
          In this tutorial, we will guide you through the installation process.
        </p>
        <p>
          If you live in Iran, the first step is to <b>connect to your VPN for
          installation</b> because Iran is banned by Google. But for the next times,
          there is no need for VPN.
        </p>
        <p>
          After installation, if there was no chrome icon inside app logo,
          it means that you have installed it successfully.
        </p>
      </div>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Android</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{justifyContent: 'space-around'}}>
          <div>
            <h3>1. Open learnlyric.com in chrome.</h3>
            <h3>2. Click on the menu button</h3>
            <img src="/static/img/pwa-android-1.jpg" alt="PWA Android Tutorial"/>
            <h3>3. Select Add to Home Screen</h3>
            <img src="/static/img/pwa-android-2.jpg" alt="PWA Android Tutorial"/>
            <h3>3. Select Add</h3>
            <img src="/static/img/pwa-android-3.jpg" alt="PWA Android Tutorial"/>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>iOS</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{justifyContent: 'space-around'}}>
          <div>
            <h3>1. Tap on middle button</h3>
            <img src="/static/img/pwa-ios-1.jpg" alt="PWA iOS Tutorial"/>
            <h3>2. Find and Select Add to Home Screen</h3>
            <img src="/static/img/pwa-ios-2.jpg" alt="PWA iOS Tutorial"/>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default Tutorial;
