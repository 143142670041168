import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { filtersToKey } from '../utils';

import {
  SONG_RECEIVED,
  PROMOTIONS_RECEIVED,
  SONG_LIST_RECEIVED,
  SET_FILTER,
  SET_ENVIRNMENT,
  LANGUAGES_RECEIVED,
  TOGGLE_SEARCH_DIALOG,
  TOGGLE_DRAWER,
  TOGGLE_TRANS,
  ENABLE_TRANS,
  SEARCH_RESULT_RECEIVED,
  SET_SEARCH_TERM,
  START_SEARCHING,
  END_SEARCHING
} from '../actionTypes';


const songs = (songs = {}, action) => {
  switch (action.type) {
  case SONG_RECEIVED:
    const song = action.payload;
    return {
      ...songs,
      [song.id]: song,
    };

  case PROMOTIONS_RECEIVED:
    const promotions = action.payload.results;
    const promotionsLength = promotions.length;
    const _songs = {};
    for (let i = 0; i < promotionsLength; i++) {
      const promotion = promotions[i];
      const itemsLength = promotion.firstItems.length;
      for (let x = 0; x < itemsLength; x++) {
        const item = promotion.firstItems[x];
        _songs[item.id] = item;
      }
    };
    return {
      ...songs,
      ..._songs,
    };

  case SONG_LIST_RECEIVED:
    const { results } = action.payload;
    return {
      ...songs,
      ...Object.assign({}, ...(results.map(song => ({ [song.id]: song})))),
    };

  default:
    return songs;
  }
}

const promotions = (promotions = {results: [], isMore: true}, action) => {
  switch (action.type) {
  case PROMOTIONS_RECEIVED:
    const newPromotions = action.payload.results;
    const length = newPromotions.length;
    const _results = [];
    for (let i = 0; i < length; i++) {
      const promotion = newPromotions[i];
      const _firstItems = [];
      const itemsLength = promotion.firstItems.length;
      for (let x = 0; x < itemsLength; x++) {
        _firstItems.push(promotion.firstItems[x].id);
      }
      _results.push({
        ...promotion,
        firstItems: _firstItems,
      });
    };

    return {
      isMore: action.payload.next !== null,
      results: [
        ...promotions.results,
        ..._results
      ]
    };
  default:
    return promotions;
  }
}


const initialFilters = {
  language: '', promotionId: '', genreId: ''
};
const filters = (filters = initialFilters, action) => {
  switch(action.type) {
  case SET_FILTER: {
    return {
      ...initialFilters,
      ...action.filters,
    };
  }
  default:
    return filters;
  }
};

const songLists = (songLists = {}, action) => {
  switch(action.type) {
  case SONG_LIST_RECEIVED:
    const { results } = action.payload;
    const listKey = filtersToKey(action.filters);
    const songList = songLists[listKey] || {
      isMore: true,
      results: []
    };
    const _results = [...songList.results, ...results.map((song) => song.id)];

    return {
      ...songLists,
      [listKey]: {
        count: action.payload.count,
        results: _results,
        isMore: action.payload.next !== null
      },
    };

  case PROMOTIONS_RECEIVED:
    const promotions = action.payload.results;
    const promotionsLength = promotions.length;
    const _songLists = {};
    for (let i = 0; i < promotionsLength; i++) {
      const promotion = promotions[i];
      const listKey = filtersToKey({promotionId: promotion.id});
      const results = promotion.firstItems.map((song) => song.id);
      _songLists[listKey] = {
        count: promotion.itemsCount,
        results,
        isMore: promotion.itemsCount > results.length
      };
    }
    return {
      ...songLists,
      ..._songLists,
    };
  default:
    return songLists;
  }
};


const initialEnvironment = {
  wasNetworkConnected: true,
  loading: true,
  drawerOpen: false,
  searchDialogOpen: false,
  trans: true,
  searchTerm: '',
  searching: false,
};

const environment = (environment = initialEnvironment, action) => {
  switch (action.type) {
  case SET_ENVIRNMENT:
    return {
      ...environment,
      ...action.environment,
    };
  case TOGGLE_DRAWER:
    return {
      ...environment,
      drawerOpen: !environment.drawerOpen
    };
  case TOGGLE_SEARCH_DIALOG:
    return {
      ...environment,
      searchDialogOpen: !environment.searchDialogOpen,
    };
  case TOGGLE_TRANS:
    return {
      ...environment,
      trans: !environment.trans,
    };
  case ENABLE_TRANS:
    return {
      ...environment,
      trans: true,
    };
  case SET_SEARCH_TERM:
    return {
      ...environment,
      searchTerm: action.term,
    };
  case START_SEARCHING:
    return {
      ...environment,
      searching: true,
    };
  case END_SEARCHING:
    return {
      ...environment,
      searching: false,
    };

  default:
    return environment;
  }
};

const languages = (languages = [{code: '', name: 'All'}], action) => {
  switch(action.type) {
  case LANGUAGES_RECEIVED:
    return [
      ...languages,
      ...action.languages
    ];
  default:
    return languages;
  }
}

const searches = (searches = {}, action) => {
  switch (action.type) {
  case SEARCH_RESULT_RECEIVED:
    return {
      ...searches,
      [action.term]: action.payload
    };
  default:
    return searches;
  }
}


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  songs,
  promotions,
  songLists,
  filters,
  environment,
  languages,
  searches,
});

export default createRootReducer;
