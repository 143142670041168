import { createSelector } from 'reselect';

import { filtersToKey } from '../utils';


export const songsSelector = state => state.songs;
export const filtersSelector = state => state.filters;
const songListsSelector = state => state.songLists;

const currentSongListSelector = createSelector(
  filtersSelector,
  songListsSelector,
  (filters, songLists) => {
    const listKey = filtersToKey(filters);
    return songLists[listKey] || {
      results: [],
      count: 10,
    };
  }
);

export const currentSongsSelector = createSelector(
  songsSelector,
  currentSongListSelector,
  (songs, currentSongList) => {
    return currentSongList.results.map(
      (songId) => songs[songId]
    );
  }
);

export const isMoreSelector = createSelector(
  currentSongListSelector,
  (c) => c.count > c.results.length,
);


export const languagesSelector = state => state.languages;

export const promotionsSelector = createSelector(
  state => state.promotions,
  songsSelector,
  (promotions, songs) => {
    return {
      isMore: promotions.isMore,
      results: promotions.results.map(
        (res) => ({
          ...res,
          firstItems: res.firstItems.map(
            (songId) => songs[songId]
          ),
        })
      )
    };
  }
);
