import React from 'react';

import Slider from './Slider';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';
import Replay5 from '@material-ui/icons/Replay5';
import Forward5 from '@material-ui/icons/Forward5';
import Share from '@material-ui/icons/Share';
import GetApp from '@material-ui/icons/GetApp';
import LinearProgress from '@material-ui/core/LinearProgress';

import noSleep from 'nosleep.js';

import { withSnackbar } from 'notistack';

import urls from '../urls';
import {
  getCurrentTime,
  getProgress,
  getFormattedTime,
  isNetworkConnected
} from '../utils';



class Player extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.audioPlayer = React.createRef();
    this.noSleep = new noSleep();
  }

  getInitialState = () => ({
    current: 0,
    progress: 0,
    buffered: 0,
    duration: 0,
    playing: false,
    userInteract: false,
    waiting: false,
  })

  getPlayer = () => this.audioPlayer.current;

  componentDidMount = () => {
    document.addEventListener('keydown', this.onKeyPressed);
    const player = this.getPlayer();
    player.onended = () => {
      player.currentTime = 0;
      this.setState({playing: false});
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.song.id !== prevProps.song.id) {
      this.setState(this.getInitialState());
    }
  }

  updateMediaInfo = () => {
    if (!window.navigator || !('mediaSession' in window.navigator)) {
      return;
    }
    const { song } = this.props;
    const mediaSession = window.navigator.mediaSession;
    mediaSession.metadata = new window.MediaMetadata({
      title: song.title,
      artist: song.artist,
      album: song.album,
      artwork: [
        {src: urls.imageUrl(song.artwork, 200, 200, 'cover')}
      ]
    });
    mediaSession.setActionHandler('play', this.onPlayPauseClicked);
    mediaSession.setActionHandler('pause', this.onPlayPauseClicked);
    mediaSession.setActionHandler('seekbackward', this.backward);
    mediaSession.setActionHandler('seekforward', this.forward);
  }

  getSnapshotBeforeUpdate = (prevProps, prevState) => {
    if (this.props.song.id !== prevProps.song.id) {
      if (this.noSleep) {
        this.noSleep.disable();
      }
    }
    return null;
  }

  componentWillUnmount = async () => {
    const player = this.getPlayer();
    await player.pause();
    player.onended = null;
    player.currentTime = 0.0;
    player.src = "";
    await player.load();
    await player.remove();

    await document.removeEventListener('keydown', this.onKeyPressed);
    await this.destroyNoSleep();
    if (window.navigator && ('mediaSession' in window.navigator)) {
      window.navigator.mediaSession.metadata = null;
    }
  }

  onWaiting = () => {
    this.setState({waiting: true});
  }

  onKeyPressed = (e) => {
    if (!this.props.shortkeysEnabled) {
      return;
    }
    switch (e.code) {
    case "ArrowRight":
      e.preventDefault();
      this.forward();
      break;
    case "ArrowLeft":
      e.preventDefault();
      this.backward();
      break;
    case "Space":
      e.preventDefault();
      this.onPlayPauseClicked();
      break;
    default:
      break;
    }
  }

  destroyNoSleep = async () => {
    if (this.noSleep) {
      await this.noSleep.disable();
      const video = document.getElementsByTagName('video')[0];
      if (video !== undefined) {
        await video.remove();
      }
      this.noSleep = null;
    }
  }

  onTimeUpdate = (e) => {
    this.props.onTimeUpdate(e);
    const player = this.getPlayer();
    this.setState({
      current: Number.parseInt(player.currentTime),
      progress: getProgress(player.currentTime, player.duration),
      duration: player.duration || 0,
      buffered: this.getBuffered(),
      waiting: false,
    });
  }

  getBuffered = () => {
    const player = this.getPlayer();
    let bufferedSec = 0;
    if (player.buffered.length > 0) {
      bufferedSec = player.buffered.end(player.buffered.length - 1);
    }
    return Number.parseInt((bufferedSec / player.duration) * 100);
  }

  onProgress = (e) => {
    this.setState({
      buffered: this.getBuffered(),
    });
  }

  handleTimeChange = (progress) => {
    const player = this.getPlayer();
    const currentTime = getCurrentTime(progress, player.duration);
    if (!isNaN(currentTime)) {
      player.currentTime = currentTime;
    }
  }

  forward = () => {
    const player = this.getPlayer();
    player.currentTime += 5;
  }

  backward = () => {
    const player = this.getPlayer();
    player.currentTime -= 5;
  }

  onPause = (e) => {
    this.setState({playing: false});
    this.noSleep.disable();
    this.updateMediaInfo();
  }

  onPlay = (e) => {
    this.setState({playing: true});
    this.updateMediaInfo();
  }

  forcePlay = async () => {
    if (!this.state.playing && this.state.userInteract) {
      await this.onPlayPauseClicked();
      await this.setState({userInteract: false});
    }
  }

  forcePause = async () => {
    if (this.state.playing) {
      await this.onPlayPauseClicked();
      await this.setState({userInteract: true});
    }
  }

  onPlayPauseClicked = async (e) => {
    const player = this.getPlayer();
    if (!player) {
      return;
    }
    if (player.error !== null) {
      if (!isNetworkConnected()) {
        this.showError("Network Error");
        player.pause();
        this.setState({playing: false});
        return;
      } else {
        // reset source
        player.src = "";
        player.src = this.audioPath();
      }
    }

    let playing = false;
    if (player.paused) {
      try {
        await player.play();;
        playing = true;
        this.noSleep.enable();
      } catch (e) {
        player.pause();
      }
    } else {
      player.pause();
    }
    this.setState({playing});
  }

  renderPlayPauseIcon = () => {
    const props = {
      onClick: this.onPlayPauseClicked,
      fontSize: 'large',
      className: 'pointer',
      style: {
        borderRadius: '50%',
        color: 'white',
        fontSize: '50px',
        padding: '8px',
        backgroundColor: '#FC3769',
      }
    };
    return this.state.playing ? <Pause {...props} /> : <PlayArrow {...props} />;
  }

  onError = (e) => {
    e.preventDefault();
    this.getPlayer().pause();
    this.setState({playing: false});
    this.showError("Network Connection Error");
  }

  showError = (message) => {
    this.props.enqueueSnackbar(
      message,
      {variant: "error", autoHideDuration: 2000}
    );
  }

  showSuccess = (message) => {
    this.props.enqueueSnackbar(
      message,
      {variant: "success", autoHideDuration: 2000}
    );
  }

  onShare = (e) => {
    e.preventDefault();
    const url = window.location.href;
    if (window.navigator.share) {
      const { song } = this.props;
      window.navigator.share({
        title: `${song.artist} - ${song.title}`,
        text: '',
        url,
      });
      return;
    }
    const elem = document.createElement('input');
    document.body.appendChild(elem);
    elem.value = url;
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    this.showSuccess("Link copied to clipboard!");
  }

  audioPath = () => {
    const { song } = this.props;
    const fileName = `${song.artist} - ${song.title}`;
    return urls.downloadAudio(
      this.props.song.audio,
      `${encodeURI(fileName.replace(/&/g, 'and', -1))}.mp3`,
    );
  }

  onDownload = async (e) => {
    await e.preventDefault();
    const elem = await document.createElement('a');
    await document.body.appendChild(elem);
    elem.href = this.audioPath();
    elem.itemprop = 'audio';
    elem.download = true;
    elem.click();
    this.showSuccess('Soon download will be started.');
  }

  render = () => {
    const { current, progress, duration, buffered, playing, waiting } = this.state;
    const { song } = this.props;
    return (
      <div className='player'>
        <audio
          preload="metadata"
          ref={this.audioPlayer}
          src={this.audioPath()}
          onProgress={this.onProgress}
          onTimeUpdate={this.onTimeUpdate}
          onPause={this.onPause}
          onPlay={this.onPlay}
          onWaiting={this.onWaiting}
          onError={this.onError}
        />
        {playing && waiting
         ? <LinearProgress
             className='indeterminate'
             style={{width: '100%'}}
             color="secondary" />
         : <div className='indeterminate'/>}
        <div className="player-song-info">
          <p>
            {`${song.artist} - ${song.title}`}
          </p>
        </div>
        <div className='time-container'>
          <span>
            {getFormattedTime(current)}
          </span>
          <Slider
            value={progress}
            buffered={buffered}
            forcePause={this.forcePause}
            forcePlay={this.forcePlay}
            max={100}
            onChange={this.handleTimeChange}
          />
          <span>
            {getFormattedTime(duration)}
          </span>
        </div>
        <div className='button-container'>
          <Share
            className='pointer grey-item'
            style={{fontSize: '32px'}}
            onClick={this.onShare}
          />
          <Replay5
            className='pointer grey-item'
            fontSize='large'
            onClick={this.backward}
          />
          {this.renderPlayPauseIcon()}
          <Forward5
            className='pointer grey-item'
            fontSize='large'
            onClick={this.forward}
          />
          <GetApp
            className='pointer grey-item'
            fontSize='large'
            onClick={this.onDownload}
          >
          </GetApp>
        </div>
      </div>
    );
  }
}


export default withSnackbar(Player);
