import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

import CircularProgress from '@material-ui/core/CircularProgress';


import urls from '../urls';
import { pageview, filterByKey, arrayEqual } from '../utils';



class SongList extends Component {
  constructor(props) {
    super(props);
    let params = queryString.parse(this.props.history.location.search);
    params = filterByKey(
      params,
      key => ['promotionId', 'language', 'genreId'].indexOf(key) !== -1
    );
    this.props.applyFilters(params);
    window.scrollTo(0, 0);
  }

  componentDidMount = () => {
    this.props.fetchMoreSongs();
    window.addEventListener('scroll', this.onScroll);
    pageview();
  }

  shouldComponentUpdate = (nextProps) => {
    return this.props.isLoading !== nextProps.isLoading ||
      !arrayEqual(
        this.props.songs.map(s => s.id),
        nextProps.songs.map(s => s.id)
      );
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps &&
        prevProps.songs.length &&
        this.props.songs.length === 0) {
      this.props.fetchMoreSongs();
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = (e) => {
    const {
      isLoading, isMore, fetchMoreSongs, wasNetworkConnected
    } = this.props;

    if ((isLoading && wasNetworkConnected) || !isMore) {
      return;
    }
    const scrollingElement = document.scrollingElement || document.documentElement;
    const endOfPage = window.innerHeight + scrollingElement.scrollTop
          > scrollingElement.offsetHeight - 200;
    if (endOfPage) {
      fetchMoreSongs(true);
    }
  }

  render = () => {
    const { props } = this;
    return (
      <Fragment>
        <Helmet>
          <title>Learnlyric</title>
        </Helmet>
        {props.songs.map(this.renderCard)}
        {props.isLoading
         ? (<div className='center'
                 style={{paddingTop: 10}}>
              <CircularProgress color='secondary'/>
            </div>)
         : null}
      </Fragment>
    );
  }

  renderCard = (song) => {
    return (
      <Link
        className="song-card"
        key={song.id}
        to={{pathname:`/songs/${song.id}/`, state: {song: song}}}
      >
        <div className='description-column'>
          <p>{song.artist} - {song.title}</p>
          <p>Language: {song.language}</p>
          <p>Genre: {song.genres.map(i => i.name).join(', ')}</p>
        </div>
        <img src={urls.imageUrl(song.artwork, 100, 100, 'cover')}
             alt={`${song.artist} - ${song.album}`} />
      </Link>
    );
  }
}

SongList.propTypes = {
  songs: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  wasNetworkConnected: PropTypes.bool.isRequired,
  isMore: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  fetchMoreSongs: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
};

export default SongList;
