import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import urls from '../urls';
import { request } from '../request';

import LyricLinesView from './LyricLinesView';
import Player from './Player';
import CircularProgress from '@material-ui/core/CircularProgress';
import { pageview, mapKeys, toCamel } from '../utils';


class SongDetail extends Component {
  constructor(props) {
    super(props);
    const locationState = this.props.history.location.state;
    const windowState = window.initialProps;
    let song = null;
    if (locationState) {
      song = locationState.song;
    } else if (windowState) {
      song = mapKeys(windowState.song, toCamel);
      window.initialProps = null;
    }
    let initialState = {};
    if (song && song.subtitleLines) {
      initialState = this.getStateFromSong(song);
    } else {
      initialState = {song: song, firstStart: 0, lastEnd: 0};
    }
    this.state = {...initialState};

    this.contentContainer = React.createRef();
  }

  getStateFromSong = (song) => {
    let firstStart = 0;
    let lastEnd = 0;
    const linesCount = song.subtitleLines.length;
    if (linesCount > 0) {
      firstStart = song.subtitleLines[0].startTime;
      lastEnd = song.subtitleLines[linesCount - 1].endTime;
    }
    return {
      song,
      firstStart,
      lastEnd,
      currentLine: 0,
      currentStart: 0,
      currentEnd: 0
    };
  }

  shouldComponentUpdate = (nextProps, nextState) => (
    this.state.song !== nextState.song ||
      this.state.currentLine !== nextState.currentLine ||
      this.props.match.params.id !== nextProps.match.params.id ||
      this.props.searchDialogOpen !== nextProps.searchDialogOpen
  )

  componentDidMount = async() => {
    const { song } = this.state;
    if (!song || !song.subtitleLines) {
      this.fetchData(this.props.match.params.id);
    }
    pageview();

    // prevent scroll overlapping
    // it will be removed in componentWillUnmount
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchData(this.props.match.params.id);
      pageview();
    }
  }

  componentWillUnmount = () => {
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
  }

  fetchData = async(songId) => {
    const url = urls.songDetail(songId);
    const song = await request(url);
    this.setState({...this.getStateFromSong(song)});
  }

  renderHeader = () => {
    const {artist, title} = this.state.song;
    return (
      <Helmet>
        <title>{`${artist} - ${title}`}</title>
      </Helmet>
    );
  }

  renderProgress = () => {
    return (
      <div className='center'
           style={{alignItems: 'center',
                   flexDirection: 'column',
                   justifyContent: 'center',
                   display: 'flex',
                   marginTop: '30vh'}}>
        <CircularProgress color='secondary'/>
      </div>
    );
  }

  setCurrentLine = (second) => {
    const mil = (second * 1000) + 300;
    const { currentStart, currentEnd, firstStart, lastEnd, song } = this.state;

    if ((currentStart <= mil && currentEnd >= mil)) {
      return;
    }
    if (!song.subtitleLines) {
      return;
    }

    let idx = 0;
    if (firstStart > mil) {
      idx = 0;
    } else if (lastEnd < mil) {
      idx = song.subtitleLines.length - 1;
    } else {
      idx = this.findLineIndex(mil);
    }
    if (idx === this.state.idx) {
      return;
    }
    if (idx !== -1) {
      const line = song.subtitleLines[idx];
      this.setState({
        currentLine: idx,
        currentStart: line.startTime,
        currentEnd: line.endTime,
      });
    }
  }

  findLineIndex = (mil) => {
    const lines = this.state.song.subtitleLines;
    const linesCount = lines.length;
    let left = 0;
    let right = linesCount - 1;
    while(left <= right) {
      const middle = left + Math.floor((right - left) / 2);
      let line = lines[middle];
      if (line.startTime <= mil && line.endTime > mil) {
        return middle;
      }
      if (mil >= line.endTime) {
        left = middle + 1;
      } else  if (mil < line.startTime) {
        right = middle - 1;
      }
    };
    return -1;
  }

  onTimeUpdate = (e) => {
    this.setCurrentLine(e.target.currentTime);
  }

  render = () => {
    const { song, currentLine } = this.state;
    if (song === null) {
      return this.renderProgress();
    }

    return (
      <div className='song-detail'>
        {this.renderHeader()}
        <div className='song-content' ref={this.contentContainer}>
          {song.subtitleLines
           ? <LyricLinesView
               currentLine={currentLine}
               lines={song.subtitleLines}
               containerRef={this.contentContainer}
             />
           : this.renderProgress()}
        </div>
        <Player
          song={song}
          shortkeysEnabled={!this.props.searchDialogOpen}
          onTimeUpdate={this.onTimeUpdate}
          songName={`${song.artist} - ${song.title}`}
        />
      </div>
    );
  }
}


export default SongDetail;
