import ReactGA from 'react-ga';


export const toCamel = (s) => (
  s.replace(/([-_][a-z])/ig, $1 => (
    $1.toUpperCase().replace('-', '').replace('_', '')
  ))
);

export const toSnake = (s) => (
  s.split(/(?=[A-Z])/).join('_').toLowerCase()
);

export const toTitle = (str) => (
  str.replace(
      /\w\S*/g,
    (txt) => `${txt.charAt(0).toUpperCase()}${txt.substr(1).toLowerCase()}`
  )
);

const isObject = (o) => (
  o === Object(o) && !Array.isArray(o) && typeof o !== 'function'
);

export const mapKeys = (o, fun) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[fun(k)] = mapKeys(o[k], fun);
      });

    return n;
  } else if (Array.isArray(o)) {
    return o.map((i) => {
      return mapKeys(i, fun);
    });
  }
  return o;
};

export const isEmpty = (obj) => {
  if (Array.isArray(obj)) {
    return obj.length === 0;
  }

  return Object.keys(obj).length === 0;
};

export const getProgress = (currentTime, duration) => (
  Math.round(10000 * (currentTime / duration)) / 100
);

export const getCurrentTime = (progress, duration) => (
  parseFloat((progress * duration) / 100)
);

export const appendZero = number => (number < 10 ? `0${number}` : number);

export const getFormattedTime = time => {
  const dateTime = new Date(0, 0, 0, 0, 0, time, 0);

  const dateTimeM = appendZero(dateTime.getMinutes());
  const dateTimeS = appendZero(dateTime.getSeconds());

  return `${dateTimeM}:${dateTimeS}`;
};


export const offsetLeft = (element) => {
  let el = element;
  let x = el.offsetLeft;

  while (el.offsetParent) {
    x += el.offsetParent.offsetLeft;
    el = el.offsetParent;
  }

  return x;
};


export const pageview = () => {
  if (process.env.NODE_ENV === 'production') {
    const page = window.location.pathname + window.location.search;
    ReactGA.pageview(page);
  }
};

export const isNetworkConnected = () => {
  if ('navigator' in window) {
    return window.navigator.onLine;
  }
  return true;
};

export const filtersToKey = (filters) => {
  const { promotionId, language, genreId } = filters;
  return `${language || 'all'}-${promotionId || 'null'}-${genreId || 'null'}`;
};


export const filterByKey = (dict, lambda) => {
  return Object.keys(dict).filter(lambda).reduce(
    (obj, key) => {
        obj[key] = dict[key];
        return obj;
    },
    {}
  );
};


export const arrayEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};
