import React from 'react';
import { connect } from 'react-redux';

import Promotions from '../components/Promotions';
import {
  fetchMorePromotions,
  setFilters,
} from '../actions';

import {
  promotionsSelector
} from '../selectors/songs';



const PromotionsContainer = props => <Promotions {...props} />;

const mapStateToProps = state => {
  return {
    promotions: promotionsSelector(state),
    isLoading: state.environment.loading,
    wasNetworkConnected: state.environment.wasNetworkConnected,
    isMore: state.promotions.isMore,
    filters: state.filters,
    languages: state.languages,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMorePromotions: (force) => dispatch(fetchMorePromotions(force)),
  applyFilters: (filters) => {
    dispatch(setFilters(filters));
  }
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PromotionsContainer);
