import {
  SET_ENVIRNMENT,
  SONG_LIST_RECEIVED,
  PROMOTIONS_RECEIVED,
  SET_FILTER,
  LANGUAGES_RECEIVED,
  TOGGLE_DRAWER,
  TOGGLE_SEARCH_DIALOG,
  TOGGLE_TRANS,
  ENABLE_TRANS,
  SEARCH_RESULT_RECEIVED,
  SET_SEARCH_TERM,
  START_SEARCHING,
  END_SEARCHING,
} from '../actionTypes';

import { filtersToKey } from '../utils';
import urls from '../urls';
import { request } from '../request';
import { isNetworkConnected } from '../utils';



export function startLoading() {
  return {
    type: SET_ENVIRNMENT,
    environment: {
      loading: true
    }
  };
}

export function endLoading() {
  return {
    type: SET_ENVIRNMENT,
    environment: {
      loading: false
    }
  };
}

export function openDrawer() {
  return {
    type: SET_ENVIRNMENT,
    environment: {
      drawerOpen: true,
    }
  };
}

export function closeDrawer() {
  return {
    type: SET_ENVIRNMENT,
    environment: {
      drawerOpen: false
    }
  };
}

export function toggleDrawer() {
  return {
    type: TOGGLE_DRAWER,
  };
}


export function toggleSearchDialog() {
  return {
    type: TOGGLE_SEARCH_DIALOG,
  };
}

export function toggleTrans() {
  return {
    type: TOGGLE_TRANS,
  };
}

export function enableTrans() {
  return {
    type: ENABLE_TRANS,
  };
}

export function openSearchDialog() {
  return {
    type: SET_ENVIRNMENT,
    environment: {
      searchDialogOpen: true,
    }
  };
}

export function closeSearchDialog() {
  return {
    type: SET_ENVIRNMENT,
    environment: {
      searchDialogOpen: false
    }
  };
}


export function networkConnected() {
  return {
    type: SET_ENVIRNMENT,
    environment: {
      wasNetworkConnected: true
    }
  };
}

export function networkDisconnected() {
  return {
    type: SET_ENVIRNMENT,
    environment: {
      wasNetworkConnected: false
    }
  };
}

export function songListReceived(payload, filters) {
  return [
    endLoading(),
    networkConnected(),
    {type: SONG_LIST_RECEIVED,
     payload,
     filters},
  ];
}

export function languagesReceived(languages) {
  return {
    type: LANGUAGES_RECEIVED,
    languages
  };
}

export function setFilters(filters) {
  return {
    type: SET_FILTER,
    filters
  };
}

export function fetchMoreSongs(force) {
  return async (dispatch, getState) => {
    const state = getState();
    const filters = state.filters;
    const listKey = filtersToKey(filters);
    const songList = state.songLists[listKey] || {
      isMore: true,
      results: []
    };
    if (songList.results.length > 0 && !force) {
      return;
    }
    const offset = songList.results.length;
    const { wasNetworkConnected } = state.environment;
    dispatch(startLoading());
    try {
      const data = await request(
        urls.songList(10, offset), {body: filters}
      );
       dispatch(songListReceived(data, filters));
    } catch (e) {
      handleRequestError(e, wasNetworkConnected, dispatch);
    }
  };
}

function handleRequestError(e, wasNetworkConnected, dispatch) {
  if (!isNetworkConnected()) {
    if (wasNetworkConnected) {
      // this.props.enqueueSnackbar(
      //   "Network is not connected",
      //   {variant: "error", autoHideDuration: 2000}
      // );
      dispatch(networkDisconnected());
    }
  } else {

    // this.props.enqueueSnackbar(
    //   e.message || e.statusText,
    //   {variant: "error", autoHideDuration: 2000}
    // );
  }

}

export function fetchLanguages() {
  return async (dispatch, getState) => {
    const languages = await request(urls.languages);
    dispatch(languagesReceived(languages));
  };
}


export function fetchMorePromotions(force) {
  return async (dispatch, getState) => {
    const { promotions, environment } = getState();

    if (promotions.results.length > 0 && !force) {
      return;
    }
    const offset = promotions.results.length;
    dispatch(startLoading());
    try {
      const data = await request(urls.promotions(5, offset));
      dispatch(promotionsReceived(data));
    } catch (e) {
      handleRequestError(e, environment.wasNetworkConnected, dispatch);
    }
  };
}


export function promotionsReceived(payload) {
  return [
    endLoading(),
    networkConnected(),
    {type: PROMOTIONS_RECEIVED,
     payload},
  ];
}

export function setSearchTerm(term) {
  return {
    type: SET_SEARCH_TERM,
    term
  };
}

export function startSearching(term) {
  return {
    type: START_SEARCHING
  };
}

export function endSearching(term) {
  return {
    type: END_SEARCHING
  };
}

export function fetchSearchResult() {
  return async (dispatch, getState) => {
    const { environment, searches } = getState();
    const { searchTerm } = environment;

    if (!searchTerm) {
      return;
    }
    const cachedResults = searches[searchTerm];
    if (cachedResults) {
      return;
    }
    try {
      dispatch(startSearching());
      const data = await request(urls.search, {body: {term: searchTerm}});
      dispatch(searchResultReceived(data, searchTerm));
    } catch (e) {
      handleRequestError(e, environment.wasNetworkConnected, dispatch);
      dispatch(endSearching());
    }
  };
}

export function searchResultReceived(payload, term) {
  return [
    {type: SEARCH_RESULT_RECEIVED,
     term: term,
     payload: payload},
    {type: END_SEARCHING}
  ];
}
