import React from 'react';
import { connect } from 'react-redux';

import SongDetail from '../components/SongDetail';

const SongDetailContainer = props => <SongDetail {...props} />;

const mapStateToProps = state => {
  return {
    searchDialogOpen: state.environment.searchDialogOpen,
  };
};

const mapDispatchToProps = dispatch => ({
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SongDetailContainer);
