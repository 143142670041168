import React, { Component, Fragment } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';

import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';

import { toTitle, arrayEqual } from '../utils';



const SearchResult = ({targetModel, targetId, objectName, onClick}) => (
  <Fragment >
    <ListItem
      onClick={() => onClick(targetModel, targetId)}
      button>
      <ListItemText
        primary={objectName}
        secondary={toTitle(targetModel)} />
    </ListItem>
    <Divider/>
  </Fragment>
);

class SearchDialog extends Component {
  onClick = (targetModel, targetId) => {
    switch(targetModel) {
    case "song":
      this.props.push(
        {pathname:`/songs/${targetId}/`}
      );
      break;
    case "language":
      this.props.applyFilters({
        language: targetId
      });
      this.props.push(
        `/songs?language=${targetId}`
      );
      break;
    case "genre":
      this.props.applyFilters({
        genreId: targetId
      });
      this.props.push(
        `/songs?genreId=${targetId}`
      );
      break;
    default:
      return;
    }
    this.props.closeSearchDialog();
  }

  shouldComponentUpdate = (nextProps) => (
      nextProps.searchTerm !== this.props.searchTerm ||
      nextProps.searchDialogOpen !== this.props.searchDialogOpen ||
      nextProps.searching !== this.props.searching ||
      !arrayEqual(
        nextProps.searchResults.map(r => r.objectName),
        this.props.searchResults.map(r => r.objectName),
      )
  )

  renderResults = () => {
    const items = this.props.searchResults.map(
      (result) => {
        const { targetModel, targetId } = result;
        return (
          <SearchResult
            key={`${targetModel}-${targetId}`}
            onClick={this.onClick}
            {...result}
          />
        );
      }
    );
    return <List>{items}</List>;
  }

  onInputChange = (e) => {
    const { value } = e.target;
    this.props.search(value);
  }

  render = () => {
    return (
      <Dialog
        open={this.props.searchDialogOpen}
        onClose={this.props.closeSearchDialog}
        style={{width: '100%', height: 'auto'}}
        className='search-box'
        aria-labelledby="simple-dialog-title"
        fullScreen

      >
        <AppBar style={{backgroundColor: "#17212D",
                        position: 'relative'}}>
          <Toolbar>
            <Input
              autoFocus
              value={this.props.searchTerm}
              onChange={this.onInputChange}
              style={{
                width: '100%',
                height: '60px',
                fontSize: '20px',
                color: 'white',
                paddingLeft: '10px'
              }}
              placeholder='Search language, genre, artist, song'
            />
            <IconButton
              onClick={this.props.closeSearchDialog}
              style={{color: 'gray'}}>
              <CloseIcon style={{fontSize: '30px'}} />
            </IconButton>
          </Toolbar>
        </AppBar>
        {this.props.searching
         ? <LinearProgress
                      className='indeterminate'
                      style={{width: '100%'}}
                      color="secondary" />
         : <div className='indeterminate'/>}
        {this.renderResults()}
      </Dialog>
    );
  }
}


export default SearchDialog;
