import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';

import successIcon from './success.png';
import urls from '../urls';
import { request } from '../request';
import { isNetworkConnected } from '../utils';


class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestId: null,
      captchaImage: null,
      text: '',
      email: '',
      captcha: '',
      sent: false,
      sending: false,
    };

  }

  componentDidMount = () => {
    this.refreshCaptcha();
  }

  refreshCaptcha = async () => {
    const response = await request(urls.captcha);
    const { captchaImage, requestId } = response;
    this.setState({captchaImage, requestId, captcha: ''});
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  onSubmit = async () => {
    this.setState({sending: true});
    if (!this.validate()) {
      return;
    }
    const { text, email, captcha, requestId } = this.state;
    try {
      const body = {text, email, captcha, requestId};
      await request(urls.message, {method: 'POST', body});
      this.setState({sent: true, sending: false});
    } catch(e) {
      this.setState({sending: false});
      if (e.status === 400) {
        this.refreshCaptcha();

        const errorBody = await e.json();
        Object.keys(errorBody).forEach(
          (key) => {
            errorBody[key].forEach(
              (err) => this.showMessage(err)
            );
          }
        );
      } else if (e.status === 500) {
        this.showMessage("Internal server error");
      } else if (!isNetworkConnected()) {
        this.showMessage("Check internet connection");
      } else {
        this.showMessage(e.message);
      }
    }
  }

  validate = () => {
    const { text, email, captcha } = this.state;
    let valid = true;
    if (!text) {
      this.showMessage("Text is not provided");
      valid = false;
    }
    if (email.indexOf('@') === -1) {
      this.showMessage("Enter a valid email");
      valid = false;
    }
    if (!captcha) {
      this.showMessage("Captcha is not provided");
      valid = false;
    }
    return valid;
  }

  showMessage = (message, variant = "error") => {
    this.props.enqueueSnackbar(
      message,
      {variant: variant, autoHideDuration: 5000}
    );
  }

  render = () => {
    if (this.state.sent) {
      return (
        <div className='message-sent'>
          <img src={successIcon} alt='success' />
          <h2>
            Message Sent Successfully!
          </h2>
        </div>
      );
    }
    return (
      <div className='contact'>
        <h1>Contact Us</h1>
        <TextField
          value={this.state.email}
          onChange={this.onChange}
          className='email-field'
          id="email-field"
          label="Email"
          type="email"
          name="email"
          autoComplete="email"
          margin="normal"
          variant="outlined"
          autoFocus
        />
        <TextField
          value={this.state.text}
          onChange={this.onChange}
          className='text-field'
          id="email-field"
          label="Text"
          type="text"
          name="text"
          margin="normal"
          variant="outlined"
          multiline
          rows={5}
        />
        <TextField
          value={this.state.captcha}
          onChange={this.onChange}
          id="captcha-field"
          label="Captcha"
          type="number"
          name="captcha"
          margin="normal"
          variant="outlined"
        />
        <div className='captcha'>
          <img style={{userSelect: 'none'}}
               alt='captcha'
               src={this.state.captchaImage} />
          <RefreshIcon
            className="pointer"
            onClick={this.refreshCaptcha}
            style={{color: '#848484'}}
            fontSize='large'/>
        </div>
        <Button
          onClick={this.onSubmit}
          style={{marginTop: 10}}
          variant="contained"
          color="primary"
          disabled={this.state.sending}
          size="large">
          {this.state.sending
           ? <CircularProgress/>
           : 'Send'}
        </Button>
      </div>
    );
  }
}

export default withSnackbar(Contact);
