import React from 'react';
import { connect } from 'react-redux';

import SongList from '../components/SongList';
import {
  fetchMoreSongs,
  setFilters,
} from '../actions';

import {
  currentSongsSelector,
  isMoreSelector,
  languagesSelector,
  filtersSelector
} from '../selectors/songs';


const SongListContainer = props => <SongList {...props} />;

const mapStateToProps = state => {
  return {
    songs: currentSongsSelector(state),
    isLoading: state.environment.loading,
    wasNetworkConnected: state.environment.wasNetworkConnected,
    isMore: isMoreSelector(state),
    filters: filtersSelector(state),
    languages: languagesSelector(state),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMoreSongs: (force) => dispatch(fetchMoreSongs(force)),
  applyFilters: (filters) => {
    dispatch(setFilters(filters));
  },
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SongListContainer);
