import { createSelector } from 'reselect';

const searchTermSelector = state => state.environment.searchTerm;
const searchesSelector = state => state.searches;

export const searchResultsSelector = createSelector(
  searchTermSelector,
  searchesSelector,
  (searchTerm, searches) => searches[searchTerm] || []
);
