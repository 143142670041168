const baseUrl = `${window.location.protocol}//${window.location.hostname}`;
const baseApi = `${baseUrl}/api`;


const urls = {
  token: `${baseUrl}/o/token/`,
  songList: (limit, offset) => `${baseApi}/songs/?limit=${limit}&offset=${offset}`,
  promotions: (limit, offset) => `${baseApi}/promotions/?limit=${limit}&offset=${offset}`,
  songDetail: (id) => `${baseApi}/songs/${id}/`,
  languages: `${baseApi}/songs/languages/`,
  // getAudio: (uuid) => `${baseUrl}/audios/${uuid}.mp3`,
  downloadAudio: (uuid, fileName) => `${baseUrl}/media/audios/${uuid}.mp3?filename=${fileName}`,
  imageUrl: (uuid, width, height, type) => `${baseUrl}/images/${uuid}/${width}/${height}/${type}`,
  captcha: `${baseApi}/captcha/`,
  message: `${baseApi}/message/`,
  search: `${baseApi}/search/`,
};

export default urls;
