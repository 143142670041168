import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import lozad from 'lozad';

class LozadImg extends Component {
  constructor(...args) {
    super(...args);
    this.imageRef = createRef();
    this.state = {
      loaded: false,
    };
  }

  componentDidMount = () => {
    if (this.imageRef.current) {
      this.observer = lozad(this.imageRef.current);
      this.observer.observe();
    }
  }

  onLoad = (e) => {
    this.setState({loaded: true});
  }

  render = () => {
    const { src, alt, className, width, height } = this.props;
    return (
      <img
        ref={this.imageRef}
        data-src={src} alt={alt}
        className={className}
        width={width}
        height={height}
        style={{opacity: this.state.loaded ? 1 : 0}}
        onLoad={this.onLoad}
        onError={this.onLoad}
      />
    );
  }
}

LozadImg.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

LozadImg.defaultProps = {
  alt: '.',
  className: '',
  id: undefined,
  width: null,
  height: null
};

export default LozadImg;
