import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { offsetLeft } from '../utils';

const prevent = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

class Slider extends Component {
  constructor() {
    super();
    this.domNode = null;
    this.state = {
      value: 0,
      userInteract: false,
    };
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  syncParent = () => {
    this.props.onChange(this.state.value);
  }

  onClick = (e) => {
    const percent = (e.clientX - offsetLeft(e.currentTarget)) / e.currentTarget.offsetWidth;
    this.setState({value: percent * this.props.max}, this.syncParent);
  }

  onMouseDown = () => {
    this.setState({userInteract: true});
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
    this.props.forcePause();
  }

  onMouseMove = (e) => {
    const { domNode, props } = this;
    const diff = e.clientX - offsetLeft(domNode);
    const percent = Math.min(Math.max(diff / domNode.offsetWidth, 0), 1);
    this.setState({value: percent * props.max});
  }

  onMouseUp = async () => {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    this.syncParent();
    await this.props.forcePlay();
    await this.setState({userInteract: false});
  }

  onTouchStart = (e) => {
    this.setState({userInteract: true});
    this.props.forcePause();
  }

  onTouchMove = (e) => {
    const { domNode, props } = this;
    const touch = e.changedTouches[0];
    const diff = touch.clientX - offsetLeft(domNode);
    const percent = Math.min(Math.max(diff / domNode.offsetWidth, 0), 1);
    this.setState({value: percent * props.max});
  }

  onTouchEnd = async (e) => {
    this.syncParent();
    await this.props.forcePlay();
    await this.setState({userInteract: false});
  }

  render = () => {
    const { className, max, buffered } = this.props;
    const value = this.state.userInteract ? this.state.value : this.props.value;

    const width = `${(value / max) * 100}%`;

    const barBackground = `-webkit-linear-gradient(left, rgb(255, 191, 191) 0% ${buffered}%, #ddd ${buffered}% 100%)`;

    return (
      <div
        className={`slider ${className}`}
        onClick={this.onClick}
        ref={(node) => { this.domNode = node; }}
        role="button"
        tabIndex="0"
      >
        <div className="slider__bar"
             style={{backgroundImage: barBackground,
                     userSelect: 'none'}}
        >
          {max > 0
            ? (
              <div className="slider__bar__fill" style={{ width }}>
                <div
                  className="slider__handle"
                  onClick={prevent}
                  onMouseDown={this.onMouseDown}
                  onTouchStart={this.onTouchStart}
                  onTouchMove={this.onTouchMove}
                  onTouchEnd={this.onTouchEnd}
                  onTouchCancel={this.onTouchEnd}
                  role="button"
                  tabIndex="0"
                />
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }
}

Slider.defaultProps = {
  className: '',
};

Slider.propTypes = {
  className: PropTypes.string,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  forcePause: PropTypes.func.isRequired,
  forcePlay: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};


export default Slider;
