import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import SearchDialog from '../components/SearchDialog';

import {
  setFilters,
  closeSearchDialog,
  setSearchTerm,
  fetchSearchResult,
} from '../actions';

import {
  searchResultsSelector
} from '../selectors/searches';


const SearchDialogContainer = props => <SearchDialog {...props} />;

const mapStateToProps = state => {
  const { searchTerm, searching } = state.environment;
  return {
    searchResults: searchResultsSelector(state),
    searchTerm,
    searchDialogOpen: state.environment.searchDialogOpen,
    searching
  };
};

const mapDispatchToProps = dispatch => ({
  applyFilters: (filters) => {
    dispatch(setFilters(filters));
  },
  closeSearchDialog: () => dispatch(closeSearchDialog()),
  search: (term) => {
    dispatch(setSearchTerm(term));
    dispatch(fetchSearchResult(term));
  },
  push: (path) => dispatch(push(path)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchDialogContainer);
