import React from 'react';

const NotFound = () => (
  <div style={{textAlign: 'center'}}>
    <h1 style={{marginTop: '40vh', color: 'white'}}>
      404 - Not Found
    </h1>
  </div>
);

export default NotFound;
