import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

import CircularProgress from '@material-ui/core/CircularProgress';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import LozadImg from '../utils/Lozad';

import urls from '../urls';
import { pageview } from '../utils';


class SongCard extends React.PureComponent {
  render = () => {
    const song = this.props.song;
    // const genres = song.genres.map(i => i.name).join(', ');
    return (
      <Link key={song.id} className="promotion-card"
            to={{pathname:`/songs/${song.id}/`, state: {song: song}}}>
        <div style={{width: '200px', height: '200px'}}>
        <LozadImg
          src={urls.imageUrl(song.artwork, 200, 200, 'cover')}
          alt={`${song.artist} - ${song.title}`}
        />
        </div>
        <p className="text song-artist">{song.artist}</p>
        <p className="text song-title">{song.title}</p>
        <p className="text song-lang">{song.language}</p>
      </Link>
    );
  };
}


class CardRow extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.state = {
      forwardEnabled: true,
      backwardEnabled: false,
    };
    this.scrollBy = 215 * 2;
    pageview();
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      nextState.forwardEnabled !== this.state.forwardEnabled ||
        nextState.backwardEnabled !== this.state.backwardEnabled
    );
  }

  componentDidMount = () => {
    const container = this.container.current;
    const { scrollWidth, clientWidth } = container;
    if (scrollWidth <= clientWidth) {
      this.setState({forwardEnabled: false});
    }
  }

  onScroll = (e) => {
    const { scrollLeft, offsetWidth, scrollWidth } = e.currentTarget;
    this.setState({
      backwardEnabled: scrollLeft !== 0,
      forwardEnabled: (scrollWidth - offsetWidth) !== scrollLeft
    });
  }

  scrollForward = () => {
    const container = this.container.current;
    const newPosition = Math.min(container.scrollLeft + this.scrollBy,
                                 container.scrollWidth - container.offsetWidth);
    container.scrollTo({top: 0, left: newPosition, behavior: 'smooth'});
  };

  scrollBackward = () => {
    const container = this.container.current;
    const newPosition = Math.max(container.scrollLeft - this.scrollBy, 0);
    container.scrollTo({top: 0, left: newPosition, behavior: 'smooth'});
  };

  renderCard = (song) => {
    return <SongCard song={song} key={song.id} />;
  }

  render = () => {
    const { promotion, songs } = this.props;
    const moreLink = `/songs?promotionId=${promotion.id}`;
    return (
      <div className="promotion-row" >
        <div className="promotion-top">
          <Link
            className="title"
            to={moreLink}>
            {promotion.name}
          </Link>
          <Link
            className="see-more"
            to={moreLink}>
            See More
          </Link>
        </div>
        <div className='cards-container' ref={this.container}
             onScroll={this.onScroll} >
          <NavigateBeforeRoundedIcon
            style={{display: this.state.backwardEnabled ? 'block' : 'none'}}
            className='navigate navigate-prev'
            onClick={this.scrollBackward} />
          {songs.map(this.renderCard)}
          <NavigateNextRoundedIcon
            style={{display: this.state.forwardEnabled ? 'block' : 'none'}}
            className="navigate navigate-next"
            onClick={this.scrollForward}
          />
        </div>
      </div>
    );
  }
}


class Promotions extends Component {
  componentDidMount = () => {
    const { fetchMorePromotions, promotions } = this.props;
    if (promotions.results.length === 0){
      fetchMorePromotions();
    }
    window.addEventListener('scroll', this.onScroll);
    pageview();
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = (e) => {
    const {
      isLoading, isMore, fetchMorePromotions, wasNetworkConnected
    } = this.props;

    if ((isLoading && wasNetworkConnected) || !isMore) {
      return;
    }
    const scrollingElement = document.scrollingElement || document.documentElement;
    const endOfPage = window.innerHeight + scrollingElement.scrollTop
          > scrollingElement.offsetHeight - 200;
    if (endOfPage) {
      fetchMorePromotions(true);
    }
  }

  render = () => {
    const { promotions, isLoading } = this.props;

    const loadingComponent = (
      <div className='center'
           style={{paddingTop: 10}}>
        <CircularProgress color='secondary'/>
      </div>
    );
    const rows = promotions.results.map(
      (promotion) => (
        <CardRow
          key={promotion.id}
          promotion={promotion}
          songs={promotion.firstItems}
        />
      )
    );
    return (
      <div className="promotion">
        <Helmet>
          <title>Learnlyric</title>
        </Helmet>
        {rows}
        {isLoading ? loadingComponent : null}
      </div>
    );
  }
}

Promotions.propTypes = {
  promotions: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  wasNetworkConnected: PropTypes.bool.isRequired,
  isMore: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  fetchMorePromotions: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
};

export default Promotions;
