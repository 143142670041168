import React from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';

import Layout from '../components/Layout';
import {
  openDrawer,
  closeDrawer,
  openSearchDialog,
  closeSearchDialog,
  toggleDrawer,
  toggleTrans,
} from '../actions';

const LayoutContainer = props => <Layout {...props} />;

const mapStateToProps = (state) => {
  const { drawerOpen } = state.environment;
  return {
    drawerOpen,
    location: state.router.location,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openDrawer: () => dispatch(openDrawer()),
  closeDrawer: () => dispatch(closeDrawer()),
  openSearchDialog: () => dispatch(openSearchDialog()),
  closeSearchDialog: () => dispatch(closeSearchDialog()),
  toggleDrawer: () => dispatch(toggleDrawer()),
  toggleTrans: () => dispatch(toggleTrans()),
  push: (path) => dispatch(push(path)),
  replace: (path) => dispatch(replace(path)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutContainer);
