import queryString from 'query-string';

import {toSnake, toCamel, mapKeys} from './utils';

export const request = async (urlPath, requestParams = {}) => {
  let path = urlPath.slice(0);
  const params = { ...requestParams };
  if (params.body) {
    Object.keys(params.body).forEach(key => {
      if (!params.body[key]) delete params.body[key];
    });
    params.body = mapKeys(params.body, toSnake);
    if (!params.method || params.method === 'GET') {
      const paramString = `${queryString.stringify(params.body)}`;
      if (paramString) {
        path += path.indexOf('?') > -1 ? '&' : '?';
        path += paramString;
      }
      delete params.body;
    } else {
      params.body = JSON.stringify(params.body);
    }
  }
  params.headers = new Headers({ 'Content-Type': 'application/json' });

  let response;
  try {
    response = await fetch(path, params);
  } catch (e) {
    throw Error("Network Error");
  }

  if (response.status === 204) {
    return true;
  }

  if (!response.ok) {
    console.log('response error occured');
    throw response;
  }

  response.data = await response.json();

  return mapKeys(response.data, toCamel);
};
