export const SONG_RECEIVED = 'SONG_RECEIVED';
export const PROMOTIONS_RECEIVED = 'PROMOTIONS_RECEIVED';
export const SONG_LIST_RECEIVED = 'SONG_LIST_RECEIVED';
export const SET_FILTER = 'SET_FILTER';
export const SET_ENVIRNMENT = 'SET_ENVIRNMENT';
export const LANGUAGES_RECEIVED = 'LANGUAGES_RECEIVED';
export const TOGGLE_FILTER_DIALOG = 'TOGGLE_FILTER_DIALOG';
export const TOGGLE_SEARCH_DIALOG = 'TOGGLE_SEARCH_DIALOG';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_TRANS = 'TOGGLE_TRANS';
export const ENABLE_TRANS = 'ENABLE_TRANS';
export const SEARCH_RESULT_RECEIVED = 'SEARCH_RESULT_RECEIVED';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const START_SEARCHING = 'START_SEARCHING';
export const END_SEARCHING = 'END_SEARCHING';
