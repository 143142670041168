import React from 'react';


export default class LyricLinesView extends React.Component {
  constructor(props) {
    super(props);
    this.highlight = React.createRef();
  }

  shouldComponentUpdate = (nextProps, nextState) => (
    this.props.currentLine !== nextProps.currentLine ||
      this.props.lines.length !== nextProps.lines.length
  )

  componentDidUpdate = (prevProps) => {
    if (this.props.currentLine !== prevProps.currentLine) {
      this.focusCurrentLine();
    }
  }

  focusCurrentLine() {
    const container = this.props.containerRef.current;
    const highlight = this.highlight.current;
    const pos = highlight.offsetTop - (container.clientHeight / 2) + 100;
    container.scrollTo({top: pos, left: 0, behavior: 'smooth'});
  }

  renderLine = (line, idx) => {
    const isCurrent = idx === this.props.currentLine;
    const style = isCurrent
          ? {fontWeight: '500', color: 'white'}
          : {fontWeight: 'normal'};
    return (
      <span key={idx}
            style={style}
            ref={isCurrent ? this.highlight : null}
      >
        <p>{line.original}</p>
        <p style={{direction: 'rtl'}}>{line.translation}</p>
      </span>
    );
  }

  render = () => {
    return (
      <div className='lyric-container'>
        {this.props.lines.map(this.renderLine)}
      </div>
    );
  }
}
