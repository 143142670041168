import React from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';

import Divider from '@material-ui/core/Divider';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/styles';

import SearchDialogContainer from '../containers/SearchDialogContainer';


const drawerStyles = {
  paper: {
    backgroundColor: '#17212D',
  },
  listItemText: {
    color: 'white',
  },
};

const Logo = () => {
  const textStyles = {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.4rem'
  };
  const dotStyles = {
    height: '10px',
    width: '10px',
    backgroundColor: 'red',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '3px'
  };
  return (
    <Typography
      variant="h5"
      style={textStyles}>
      learnlyric
      <span style={dotStyles}>
      </span>
    </Typography>
  );
};

class Layout extends React.Component {
  goHome = () => {
    if (this.props.location.pathname !== '/') {
      this.props.push('/');
    }
  }

  renderAppBar = () => {
    return (
      <AppBar
        position="sticky"
        style={{backgroundColor: "#17212D"}} >
        <Toolbar>
          <IconButton
            style={{marginRight: 'auto'}}
            onClick={this.props.toggleDrawer}
            color="inherit"
            aria-label="Menu">
            <MenuIcon />
          </IconButton>
          <IconButton
            onClick={this.goHome}>
            <Logo />
          </IconButton>
          <IconButton
            style={{marginLeft: 'auto'}}
            onClick={this.props.openSearchDialog}
            color="inherit"
            aria-label="Menu">
            <SearchIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  };

  shouldComponentUpdate = (nextProps) => (
    this.props.drawerOpen !== nextProps.drawerOpen
  )

  renderDrawer = () => {
    return (
      <SwipeableDrawer
        open={this.props.drawerOpen}
        onClose={this.props.toggleDrawer}
        onOpen={this.props.toggleDrawer}
      >
        <div
          tabIndex={0}
          onClick={this.props.toggleDrawer}
          role="button">
          <div style={{width: 250}}>
            <Typography
              style={{textAlign: 'center',
                      background:'#17212D',
                      color: 'white',
                      padding: 25}}
              variant="h6"
              color="inherit"
              noWrap>
              Learnlyric.com
            </Typography>
            <Divider/>
            <List>
              <ListItem
                onClick={this.goHome}
                button >
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem
                onClick={() => window.open('https://t.me/learnlyric', '_blank')}
                button>
                <ListItemText primary="Telegram" />
              </ListItem>
              <ListItem
                onClick={() => window.open('https://twitter.com/learnlyric', '_blank')}
                button >
                <ListItemText primary="Twitter" />
              </ListItem>
              <ListItem
                onClick={() => this.props.push('/contact/')}
                button>
                <ListItemText primary="Contact" />
              </ListItem>
              <ListItem
                onClick={() => this.props.push('/tutorial/')}
                button>
                <ListItemText primary="Tutorial" />
              </ListItem>
            </List>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }

  render = () => {
    return (
      <React.Fragment>
        {this.renderAppBar()}
        {this.renderDrawer()}
        {<SearchDialogContainer />}
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  openDrawer: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  openSearchDialog: PropTypes.func.isRequired,
  closeSearchDialog: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  toggleTrans: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};


export default withStyles(drawerStyles)(Layout);
