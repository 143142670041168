import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Tutorial from './Tutorial';
import Contact from './Contact';

import SongDetailContainer from '../containers/SongDetailContainer';
import LayoutContainer from '../containers/LayoutContainer';
import PromotionsContainer from '../containers/PromotionsContainer';
import SongListContainer from '../containers/SongListContainer.js';

import NotFound from './NotFound';

import ReactGA from 'react-ga';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/browser';


if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://21318aebb80f496e9f0ad75a8c8f1826@sentry.teamapp.app/10",
    release: 3,
  });
}

export default class App extends React.PureComponent {
  constructor(props) {
    super(props);
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('UA-139939223-1');
    }
  }

  componentDidCatch = (error, info) => {
    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(error, {extra: info});
    }
  }

  render = () => {
    return (
      <SnackbarProvider maxSnack={3}>
        <LayoutContainer />
        <Switch>
          <Route
            path={'/songs'}
            exact
            component={SongListContainer}
          />
          <Route
            exact
            path={'/'}
            component={PromotionsContainer}
          />
          <Route
            exact
            path={'/songs/:id/'}
            component={SongDetailContainer}
          />
          <Route
            exact
            path={'/contact/'}
            component={Contact}
          />
          <Route
            exact
            path={'/tutorial/'}
            component={Tutorial}
          />
          <Route component={NotFound}/>
        </Switch>
      </SnackbarProvider>
    );
  };
}
